<template>
  <div>
    <v-list v-if="!comment_loading" class="comments">
      <v-list-item
        class="comments-item"
        v-for="comment in comments"
        :key="comment.id"
      >
        <v-list-item-avatar size="24">
          <img :src="comment.user.avatar" alt="" />
        </v-list-item-avatar>
        <v-list-item-content>
          <p class="pr-2">
            <strong class="mr-2">{{ comment.user.name }}</strong>
            {{ comment.comment.comment }}
          </p>
        </v-list-item-content>
        <v-list-item-action-text>
          {{ comment.comment.created_at }}
        </v-list-item-action-text>
      </v-list-item>
    </v-list>

    <template v-else>
      <v-skeleton-loader
        v-for="n in 3"
        :key="n"
        type="list-item-avatar-three-line"
      ></v-skeleton-loader>
    </template>

    <v-bottom-navigation
      fixed
      :height="isIOS ? '76px' : '56px'"
      :style="`padding-bottom:${isIOS && '1rem'}`"
    >
      <div class="input-container">
        <v-form @submit.prevent="addComment" class="w-100">
          <v-textarea
            outlined
            dense
            hide-details
            rows="1"
            row-height="12"
            :auto-grow="false"
            v-model="comment"
            :placeholder="$vuetify.lang.t('$vuetify.add_your_comment')"
            ref="comment-input"
          >
            <template v-slot:prepend>
              <v-avatar size="40">
                <img :src="user.avatar" alt="" />
              </v-avatar>
            </template>

            <template v-slot:append>
              <v-icon @click="addComment" :color="comment ? 'primary' : ''"
                >mdi-send</v-icon
              >
            </template>
          </v-textarea>
        </v-form>
      </div>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
const userModule = createNamespacedHelpers("user");
const feedModule = createNamespacedHelpers("feed");

import MixDevice from "@/mixin/mix-device";
export default {
  mixins: [MixDevice],
  props: {
    post_id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      comment: "",
    };
  },
  computed: {
    ...mapState(["isCordova"]),
    ...userModule.mapState(["user"]),
    ...feedModule.mapState(["comment_loading", "comments"]),
  },
  methods: {
    ...feedModule.mapActions(["FETCH_POST_COMMENTS_PUBLIC", "ADD_FEED_POST_COMMENT"]),
    ...feedModule.mapMutations(["SET_COMMENTS"]),
    addComment() {
      if (!this.comment) return;

      this.ADD_FEED_POST_COMMENT({
        comment: this.comment,
        post_id: this.post_id,
      });

      this.comment = "";
      this.$refs["comment-input"].blur();
    },
  },
  created() {
    this.FETCH_POST_COMMENTS_PUBLIC(this.post_id);
  },
};
</script>

<style lang="scss" scoped>
.input-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  &::v-deep .v-input__prepend-outer {
    margin-top: 0 !important;
  }
}

.comments {
  padding-bottom: 3.5rem;

  &-item {
    align-items: flex-start;

    .v-list-item__content {
      padding-top: 0;
    }

    .v-list-item__avatar {
      align-self: flex-start;
      margin-top: 0;
      margin-right: 5px;
    }

    .v-list-item__content {
      align-self: flex-start;
      padding-top: 5px;
    }
  }

  p {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    color: #000000;
    margin-bottom: 0;
  }
}
</style>
