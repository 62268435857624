import { render, staticRenderFns } from "./feed-comments-public.vue?vue&type=template&id=784e2a5d&scoped=true&"
import script from "./feed-comments-public.vue?vue&type=script&lang=js&"
export * from "./feed-comments-public.vue?vue&type=script&lang=js&"
import style0 from "./feed-comments-public.vue?vue&type=style&index=0&id=784e2a5d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "784e2a5d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAvatar,VBottomNavigation,VForm,VIcon,VList,VListItem,VListItemActionText,VListItemAvatar,VListItemContent,VSkeletonLoader,VTextarea})
