<template>
  <AppPage hide-bottom-bar top-fixed>
    <template v-slot:app-bar>
      <v-btn icon @click="$router.replace('/')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-toolbar-title>{{ $vuetify.lang.t("$vuetify.photo") }}</v-toolbar-title>
      <v-spacer></v-spacer>
    </template>

    <FeedCard
      v-if="!loading"
      :feed="currentPost"
      @open-comments="commentsHandler"
      @toggle-like="toggleLikeHandler"
      @report-post="reportPostHandler"
    />

    <v-skeleton-loader
      v-else
      type="list-item-avatar,sentences, image"
    ></v-skeleton-loader>

    <FeedComments :post_id="$route.params.id" />
  </AppPage>
</template>

<script>
import FeedCard from "@/components/cards/feed-card-public";
import FeedComments from "@/components/feed/feed-comments-public";

import postActionMix from "@/mixin/post-action-mix";
export default {
  name: "post-detail",
  mixins: [postActionMix],
  components: { FeedCard, FeedComments },
  data() {
    return {};
  },
  methods: {},
  created() {
    this.FETCH_SINGLE_POST_PUBLIC(this.$route.params.id);
  },
};
</script>
